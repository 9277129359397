'use strict';

export default (() => {
	try {
		const contextRequire = require.context('.', true,
			/^\.\/(?!tools|settings).*\.scss$/);
		contextRequire.keys().forEach(contextRequire);
	} catch (error) {
		console.log(error);
	}
})();
