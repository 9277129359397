import 'cookieconsent/src/cookieconsent';
import 'picturefill';
import 'lazysizes';
import 'foundation-sites/dist/js/foundation';
import 'what-input';
import '@chenfengyuan/datepicker';
import '@chenfengyuan/datepicker/i18n/datepicker.de-DE';

import 'scss/styles.js';

/**
 * Body
 *
 * @author HDNET GmbH & Co. KG
 * @todo Refactor modules
 */
(($, window, document) => {
	const $document = $(document);
	const $window = $(window);

	const modulePromiseResolver = ({default: Module}) => {
		new Module;

		return new Promise(resolve => resolve(Module));
	};

	$document.ready(() => {
		import(/* webpackChunkName: 'module-lightbox' */'./Modules/_lightbox').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-anchorlink' */'./Modules/_anchorlink').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-form' */'./Modules/_form').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-menu' */'./Modules/_menu').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-zisu' */'./Modules/_zisu').then(modulePromiseResolver);
		import(/* webpackChunkName: 'module-slider' */'./Modules/_slider').then(modulePromiseResolver).then((Slider) => {
			new Slider('.js-stage-slider');
		});

		// Init Datepicker
		let endDate = new Date(2021, 9, 31)
		if (Date.now() < endDate) {
			endDate = Date.now();
		}

		$('[data-toggle="datepicker"]').datepicker({
			format: 'dd.mm.yyyy',
			language: 'de-DE',
			date: new Date(),
			startDate: new Date(2021, 8, 1),
			endDate: new Date()
		});

		/**
		 * Temporary way to kill the native form validation of browsers as it's
		 * buggy.
		 */
		$('form').attr('novalidate', 'novalidate');
	});

	/**
	 * Initialize foundation.
	 */
	$document.foundation();

	/**
	 * Lazy loading images.
	 */
	$document.on('lazyloaded', function (e) {
		$(e.target).closest('.lazy-picture').addClass('imagesloaded');
	});

	/**
	 * Listen for the modals to open and focus
	 * the search field whenever the search modal
	 * opens.
	 */
	$document.on('open.zf.reveal', function () {
		$(this).find('#globalSearchWord').focus();
	});

})(jQuery, window, document);

if (module.hot) {
	module.hot.accept();
}
